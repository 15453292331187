$(() => {
    const $body = $('body');

    // ポップアップ表示イベントはpopup.jsに分離
    // $('.jsMagnific').click(function (e) {
    // ...
    // });

    // ページスクロール制御
    function stopPreventDefault(event) {
        event.preventDefault();
    }

    // ヘッダ表示管理
    function initHeaderFix() {
        let pos = 0;
        $(window).on('scroll', function () {
            const $body = $('body');
            const $header = $('.jsHeader');
            const $headerMain = $('.jsHeaderMain');
            const $informationBar = $('.information-bar');
            let headerHeight = $headerMain.outerHeight();
            const currentPos = $(this).scrollTop();
            const $spacer = $('.headerSpace');

            // information barへの配慮
            if ($('.information-bar').length) {
                headerHeight += $informationBar.outerHeight();
            }
            //

            if ($('.cmn-header__navigation-pc').css('display') === 'block') {
                // ナビゲーションエリア拡大中
                $body.addClass('header-is-fixed');
                $spacer.css('height', headerHeight);
            } else if (currentPos > headerHeight) {
                if (!$body.hasClass('header-is-fixed')) {
                    $header.css('transition', 'unset').css('top', -headerHeight);
                }
                $body.addClass('header-is-fixed');
                $spacer.css('height', headerHeight);
                setTimeout(() => {
                    $header.css('transition', 'top 300ms ease-out');
                }, 1);
            // 一番上までスクロールした時ヘッダの位置を初期値にする
            } else if (currentPos === 0) {
                if ($body.hasClass('header-is-fixed')) {
                    $body.removeClass('header-is-fixed');
                    $spacer.css('height', 0);
                }
            }

            if ($('.cmn-header__navigation-pc').css('display') === 'block') {
                // ホバーして動かした時
                $header.css('top', 0);
            } else if (currentPos <= headerHeight) {
                // 初期表示
                $header.css('top', 0);
            } else if (currentPos < pos) {
                // 上スクロールした時にヘッダーを表示
                $header.css('top', 0);
            } else {
                // 下スクロールした時にヘッダーを非表示
                $header.css('top', -headerHeight);
            }
            pos = currentPos;
        });
    }
    initHeaderFix();

    // ヘッダリストー ホバー制御
    function initHeaderListHover() {
        const $list = $('.cmn-header__main__list');
        const $listItem = $('.cmn-header__main__list > ul > li');
        const $activeSign = $('.cmn-header__main__list__selected');
        const $spMenu = $('.cmn-header__main__menu__mobile');

        const $headerMain = $('.cmn-header__main');
        const $headerInner = $('.cmn-header__inner');

        const $desktopMenu = $('*[data-desktop-menu="products"],*[data-desktop-menu="trend"],*[data-desktop-menu="soon"],*[data-desktop-menu="collaboration"],*[data-desktop-menu="news"]');
        const $desktopMenuSearch = $('*[data-desktop-menu="search"]');
        const $desktopMenuMy = $('*[data-desktop-menu="my"]');

        const $desktopPreview = $('*[data-desktop-preview]');

        const $pcNavigation = $('.cmn-header__navigation-pc');
        const $pcNavigationInner = $('.cmn-header__navigation-pc__inner');
        const $pcNavigationCover = $('.cmn-header__navigation-pc, .cmn-header__main__cover');

        const $pcNavigationProducts = $('.cmn-header__navigation-pc-products');
        const $pcNavigationTrend = $('.cmn-header__navigation-pc-trend');
        const $pcNavigationCollaboration = $('.cmn-header__navigation-pc-collaboration');

        const $pcNavigationSearch = $('.cmn-header__navigation-pc-search');
        const $pcNavigationMy = $('.cmn-header__navigation-pc-my');

        const $pcNavigationPreview = $('.cmn-header__navigation-pc__preview');

        const $pcNavigationClose = $('.cmn-header__main__cover__close');

        let is_pc_menu_open = false;
        let is_pc_menu_type = null;
        const is_pc_animation = false;

        // メインエリアホバー時
        $headerMain.hover(
            () => {
                $headerMain.css('background-color', 'rgba(255, 255, 255, 1)');
            },
            () => {
                if (!is_pc_menu_open) {
                    $headerMain.css('background-color', 'rgba(255, 255, 255, 0.7)');
                }
            },
        );

        // メインメニューホバー時
        $desktopMenu.hover(
            function () {
                if ($spMenu.css('display') === 'none') {
                    is_pc_menu_open = true;
                    is_pc_menu_type = $(this).attr('data-desktop-menu');

                    const thisPosition = $(this).offset().left - $list.offset().left;
                    const thisWidth = $(this).width();
                    $activeSign.css('left', thisPosition);
                    $activeSign.css('width', thisWidth);
                    $activeSign.css('opacity', 1);

                    $pcNavigationSearch.hide();
                    $pcNavigationMy.hide();
                    $pcNavigationInner.stop();
                    if ($pcNavigationInner.hasClass('my')) {
                        $pcNavigationInner.removeClass('my');
                    }

                    if (is_pc_menu_type === 'products') {
                        $pcNavigationProducts.stop().fadeIn(300);
                        $pcNavigationTrend.stop().fadeOut(300);
                        $pcNavigationCollaboration.stop().fadeOut(300);
                    } else if (is_pc_menu_type === 'trend') {
                        $pcNavigationProducts.stop().fadeOut(300);
                        $pcNavigationTrend.stop().fadeIn(300);
                        $pcNavigationCollaboration.stop().fadeOut(300);
                    } else if (is_pc_menu_type === 'collaboration') {
                        $pcNavigationProducts.stop().fadeOut(300);
                        $pcNavigationTrend.stop().fadeOut(300);
                        $pcNavigationCollaboration.stop().fadeIn(300);
                    }

                    if ((is_pc_menu_type === 'products') || (is_pc_menu_type === 'trend') || (is_pc_menu_type === 'collaboration')) {
                        setTimeout(() => {
                            $pcNavigation.stop().fadeIn(300);
                            $pcNavigationInner.stop().css({ display: 'block' }).animate({
                                height: 360,
                            }, 300);
                        }, 1);
                    }

                    if ((is_pc_menu_type === 'soon') || (is_pc_menu_type === 'news')) {
                        $pcNavigationInner.stop().animate({
                            height: 0,
                        }, 150, () => {
                            is_pc_menu_open = false;
                        });
                    }
                }

                // information barがある&メニューが開いた状態でscrollした際の制御
                let pos = 0;
                const $header = $('.jsHeader');
                const $informationBar = $('.information-bar');
                let $informationBarHeight = $informationBar.outerHeight();

                $(window).on('scroll', function () {
                    const currentPos = $(this).scrollTop();
                    if(is_pc_menu_open === true){
                        if(currentPos === pos){
                            $header.css('top', 0);
                        }
                    }
                });
            },
            () => {},
        );

        // 検索メニュー
        $desktopMenuSearch.on('click', function (event) {
            if ($spMenu.css('display') === 'none') {
                is_pc_menu_open = true;
                is_pc_menu_type = $(this).attr('data-desktop-menu');

                $pcNavigationProducts.hide();
                $pcNavigationTrend.hide();
                $pcNavigationCollaboration.hide();
                $pcNavigationMy.hide();
                $pcNavigationInner.stop();
                if ($pcNavigationInner.hasClass('my')) {
                    $pcNavigationInner.removeClass('my');
                }

                setTimeout(() => {
                    $pcNavigationSearch.stop().fadeIn(300);
                    $pcNavigationCover.stop().fadeIn(300);
                    $pcNavigationInner.stop().css({ display: 'block' });
                    // Focus into the header search input
                    $('input.js-header-menu-search-input').trigger('focus');
                }, 1);
            }
        });

        // マイメニュー
        $desktopMenuMy.hover(
            function () {
                if ($spMenu.css('display') === 'none') {
                    is_pc_menu_open = true;
                    is_pc_menu_type = $(this).attr('data-desktop-menu');

                    $pcNavigationProducts.hide();
                    $pcNavigationTrend.hide();
                    $pcNavigationCollaboration.hide();
                    $pcNavigationSearch.hide();
                    $pcNavigationInner.stop();
                    if (!$pcNavigationInner.hasClass('my')) {
                        $pcNavigationInner.addClass('my');
                    }

                    setTimeout(() => {
                        $pcNavigationMy.stop().fadeIn(300);
                        $pcNavigation.stop().fadeIn(300);
                        $pcNavigationInner.stop().css({ display: 'block' }).animate({
                            height: 360,
                        }, 300);
                    }, 1);
                }
            },
            () => {},
        );

        // プロダクト詳細
        $desktopPreview.hover(
            function () {
                $pcNavigationPreview.empty().append(`<img src="${$(this).attr('data-desktop-preview')}" alt="">`).stop().fadeIn(300);
            },
            () => {
                $pcNavigationPreview.hide();
            },
        );

        // 閉じる挙動
        function closePcHeaderNavigation() {
            is_pc_menu_type = null;
            $headerMain.css('background-color', 'rgba(255, 255, 255, 0.7)');
            $activeSign.css('opacity', 0);
            $pcNavigationCover.stop().fadeOut(150);
            $pcNavigationInner.stop().animate({
                height: 0,
            }, 150, () => {
                is_pc_menu_open = false;
            });
            //Clear PC Search Suggestion
            $(".cmn-header__navigation-pc-search").empty();
            $('input.search-field').val('');
        }

        // ボタンでで閉じる
        $pcNavigationClose.on('click', (event) => {
            closePcHeaderNavigation();
            $headerMain.css('background-color', 'rgba(255, 255, 255, 1)');
        });

        $('.cmn-header__navigation-pc').on('click', (event) => {
            $pcNavigationClose.trigger('click');
        });

        // 範囲外で閉じる
        let is_mouseMove = false;
        $headerInner.on('mousemove', (event) => {
            if (is_mouseMove) {
                return false;
            }

            if ($spMenu.css('display') === 'none') {
                is_mouseMove = true;

                let headerHeight = 0;
                headerHeight += $headerMain.outerHeight();
                headerHeight += $pcNavigationInner.outerHeight();

                const mouseX = event.pageX - document.querySelector('.cmn-header__inner').getBoundingClientRect().left;
                const mouseY = event.pageY - document.querySelector('.cmn-header__inner').getBoundingClientRect().top;

                const my_x = document.querySelector('.cmn-header__main__menu__my').getBoundingClientRect().left;
                const my_x_min = my_x - 10;
                const my_x_max = my_x_min + document.querySelector('.cmn-header__main__menu__my').getBoundingClientRect().width + 10;

                const mainList_x = document.querySelector('.cmn-header__main__list').getBoundingClientRect().left;
                const mainList_x_min = mainList_x - 10;
                const mainList_x_max = mainList_x_min + document.querySelector('.cmn-header__main__list').getBoundingClientRect().width + 10;

                if ((mouseY - $(window).scrollTop() > headerHeight)) {
                    closePcHeaderNavigation();
                } else if ((is_pc_menu_type === 'my') && (mouseY > $headerMain.outerHeight())) {
                    if (mouseX < $body.outerWidth() - $pcNavigationInner.outerWidth()) {
                        closePcHeaderNavigation();
                    }
                } else if ((mouseY <= $headerMain.outerHeight())) {
                    if (is_pc_menu_type == 'my') {
                        if ((mouseX < my_x_min) || (mouseX > my_x_max)) {
                            closePcHeaderNavigation();
                            $headerMain.css('background-color', 'rgba(255, 255, 255, 1)');
                            is_pc_menu_type = null;
                        }
                    } else if (is_pc_menu_type == 'search') {
                        // Return to not execute the set time out code below
                        return;
                    } else if (is_pc_menu_type != null) {
                        if ((mouseX < mainList_x_min) || (mouseX > mainList_x_max)) {
                            closePcHeaderNavigation();
                            $headerMain.css('background-color', 'rgba(255, 255, 255, 1)');
                            is_pc_menu_type = null;
                        }
                    }
                }

                setTimeout(() => {
                    is_mouseMove = false;
                }, 150);
            }
        });

        // information barの領域で閉じる
        $headerInner.on('mouseleave', (event) => {
            if ($spMenu.css('display') === 'none') {
                // closePcHeaderNavigation();
            }
        });

        // リサイズで閉じる
        $(window).resize(() => {
            closePcHeaderNavigation();
        });
    }
    initHeaderListHover();

    // SP メニュー管理
    function initMobileMenu() {
        const $spMenu = $('.cmn-header__main__menu__mobile');
        const $spNavigation = $('.cmn-header__navigation-sp');
        const $spNavigationClose = $('.cmn-header__navigation-sp__close');

        let is_sp_menu_type = null;

        let clickEventType = 'touchend';
        if (window.ontouchend !== null) {
            clickEventType = 'click';
        }

        function resetInnerMenu() {
            is_sp_menu_type = null;
            $('.cmn-header__navigation-sp__head__logo').css('display', 'none');
            $('.cmn-header__navigation-sp__head__home').css('display', 'none');
            $('.cmn-header__navigation-sp-base').css('display', 'none');
            $('.cmn-header__navigation-sp-products').css('display', 'none');
            $('.cmn-header__navigation-sp-trend').css('display', 'none');
            $('.cmn-header__navigation-sp-collaboration').css('display', 'none');
            $('.cmn-header__navigation-sp-my').css('display', 'none');
            $('.cmn-header__navigation-sp-search').css('display', 'none');
        }

        $spMenu.on(clickEventType, () => {
            is_sp_menu_type = 'base';
            $body.addClass('noScroll');
            $('.cmn-header__navigation-sp__head__logo').css('display', 'block');
            $('.cmn-header__navigation-sp-base').css('display', 'block');
            $spNavigation.fadeIn(300);
        });

        $spNavigationClose.on(clickEventType, () => {
            $body.removeClass('noScroll');
            $spNavigation.fadeOut(300, () => {
                resetInnerMenu();
            });
            // Clear SP Search Suggestion
            $("#sp-search-suggestions-result").empty();
            $('input.search-field').val('');
        });

        // リサイズで閉じる
        $(window).resize(() => {
            if (is_sp_menu_type != 'search') {
                $body.removeClass('noScroll');
                $spNavigation.fadeOut(300, () => {
                    resetInnerMenu();
                });
            } else if ($spMenu.css('display') === 'none') {
                resetInnerMenu();
            }
        });

        $('*[data-mobile-menu="products"]').on(clickEventType, function () {
            if ($spMenu.css('display') !== 'none') {
                is_sp_menu_type = $(this).attr('data-mobile-menu');
                $('.cmn-header__navigation-sp__head__logo').fadeOut(300);
                $('.cmn-header__navigation-sp-base').fadeOut(300);
                $('.cmn-header__navigation-sp__head__home').fadeIn(300);
                $('.cmn-header__navigation-sp-products').fadeIn(300);
            }
        });

        $('*[data-mobile-menu="trend"]').on(clickEventType, function () {
            if ($spMenu.css('display') !== 'none') {
                is_sp_menu_type = $(this).attr('data-mobile-menu');
                $('.cmn-header__navigation-sp__head__logo').fadeOut(300);
                $('.cmn-header__navigation-sp-base').fadeOut(300);
                $('.cmn-header__navigation-sp__head__home').fadeIn(300);
                $('.cmn-header__navigation-sp-trend').fadeIn(300);
            }
        });

        $('*[data-mobile-menu="collaboration"]').on(clickEventType, function () {
            if ($spMenu.css('display') !== 'none') {
                is_sp_menu_type = $(this).attr('data-mobile-menu');
                $('.cmn-header__navigation-sp__head__logo').fadeOut(300);
                $('.cmn-header__navigation-sp-base').fadeOut(300);
                $('.cmn-header__navigation-sp__head__home').fadeIn(300);
                $('.cmn-header__navigation-sp-collaboration').fadeIn(300);
            }
        });

        $('*[data-mobile-menu="home"]').on(clickEventType, () => {
            if ($spMenu.css('display') !== 'none') {
                $('.cmn-header__navigation-sp__head__home').fadeOut(300);
                $('.cmn-header__navigation-sp-products').fadeOut(300);
                $('.cmn-header__navigation-sp-trend').fadeOut(300);
                $('.cmn-header__navigation-sp-collaboration').fadeOut(300);
                $('.cmn-header__navigation-sp__head__logo').fadeIn(300);
                $('.cmn-header__navigation-sp-base').fadeIn(300);
            }
        });

        $('*[data-mobile-menu="my"]').on(clickEventType, function () {
            if ($spMenu.css('display') !== 'none') {
                is_sp_menu_type = $(this).attr('data-mobile-menu');
                $body.addClass('noScroll');
                $('.cmn-header__navigation-sp__head__logo').css('display', 'block');
                $('.cmn-header__navigation-sp-my').css('display', 'block');
                $spNavigation.fadeIn(300);
            }
        });

        $('*[data-mobile-menu="search"]').on(clickEventType, function () {
            if ($spMenu.css('display') !== 'none') {
                is_sp_menu_type = $(this).attr('data-mobile-menu');
                $body.addClass('noScroll');
                $('.cmn-header__navigation-sp__head__logo').css('display', 'block');
                $('.cmn-header__navigation-sp-search').css('display', 'block');
                $spNavigation.fadeIn(300);
            }
        });
    }
    initMobileMenu();

    //
    function initUnderlineAnimation() {
        const $nav = $('.jsUnderLine');

        $nav.each(function () {
            const $button = $(this).find('.jsUnderlineButton');
            const $line = $('.jsUnderlineParts');

            function updateLine() {
                $button.each(function () {
                    const $this = $(this);

                    if ($this.hasClass('active')) {
                        $line.css({
                            width: $this.width(),
                            left: $this.position().left,
                        });
                    }
                });
            }
            updateLine();

            $(window).resize(() => {
                updateLine();
            });

            $button.each(function () {
                const $this = $(this);

                $this.click(function () {
                    const $clicked = $(this);

                    if (!$clicked.hasClass('active')) {
                        $line.css({
                            width: $clicked.width(),
                            left: $clicked.position().left,
                        });
                    }
                });
            });
        });
    }
    initUnderlineAnimation();

    //
    $('.jsTogglePasswordDisp').click(function () {
        const $this = $(this);
        const $form = $this.next();
        const $img = $this.find('img');
        const $hideImg = $this.find('img.hide');
        const $dispImg = $this.find('img.disp');

        if ($form.attr('type') == 'password') {
            $form.attr('type', 'text');
            $img.removeClass('active');
            $dispImg.addClass('active');
        } else {
            $form.attr('type', 'password');
            $img.removeClass('active');
            $hideImg.addClass('active');
        }
    });

    //
    function initTabToggle() {
        const $toggleButtons = $('.jsTabToggleButton');
        const $toggleContents = $('.jsTabToggleContent');

        $toggleButtons.each(function (index) {
            const $this = $(this);
            $this.data('index', index);

            $this.click(function () {
                const $clicked = $(this);
                const index = $clicked.data('index');

                if (!$clicked.hasClass('active')) {
                    $toggleButtons.removeClass('active');
                    $toggleContents.removeClass('active');

                    $clicked.addClass('active');
                    $toggleContents.eq(index).addClass('active');
                }
            });
        });
    }
    initTabToggle();

    //
    $('a[href^="#"].anchor').click(function(){
        var adjust = 20;
        var speed = 400;
        var href= $(this).attr("href");
        var target = $(href == "#" || href == "" ? 'html' : href);
        var position = target.offset().top - adjust;
        $('body,html').animate({scrollTop:position}, speed, 'swing');
        return false;
    });

    /**
     * Get recommend product to display in the content asset at home page
     */
    function getRecommendProduct() {
        let endpoint = '';
        var recommend = '';
        let requestURL = '';
        let isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
        $('.recommend-product').each(function (index, item) {
            let $currentItem = $(item);
            // recommendの前後の空白を削除
            recommend = $currentItem.data('recommend').trim();
            endpoint = $currentItem.data('url');
            if (recommend) {
                // URLオブジェクトを使用してエンドポイントを解析
                let baseUrl = new URL(endpoint);
                // __sftkCacheBuster パラメータを一時的に保存し、取り除く
                let cacheBuster = baseUrl.searchParams.get('__sftkCacheBuster');
                if (cacheBuster) {
                    baseUrl.searchParams.delete('__sftkCacheBuster');
                }
                baseUrl.searchParams.set('recommend', recommend);
                if (isMobile) {
                    baseUrl.searchParams.set('type', 'sp');
                }
                // __sftkCacheBuster パラメータを再度追加
                if (cacheBuster) {
                    baseUrl.searchParams.set('__sftkCacheBuster', cacheBuster);
                }

                requestURL = baseUrl.toString();

                $.ajax({
                    url: requestURL,
                    method: 'GET',
                }).done(function (response) {
                    let $parentItem = $currentItem.parent();
                    if (typeof response === 'string') {
                        $currentItem.append(response);
                        // Remove the parent <div class='content-asset'></div> due to breaking CSS
                        if (
                            $parentItem &&
                            $parentItem.prop('tagName') === 'DIV' &&
                            $parentItem.attr('class') === 'content-asset'
                        ) {
                            $parentItem.replaceWith($currentItem);
                        }
                    } else {
                        if (
                            $parentItem &&
                            $parentItem.prop('tagName') === 'DIV' &&
                            $parentItem.attr('class') === 'content-asset'
                        ) {
                            // Remove the parent <div class='content-asset'></div> due to unable to find the product
                            $parentItem.remove();
                        }
                    }
                });
            }
        });
    }
    getRecommendProduct();
});
